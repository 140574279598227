import { ContactDetails, XboxUsersSet } from "lib/coplay/types/FrontendTypes";
import { initializeApp } from "firebase/app";
import {
    signInWithEmailAndPassword,
    getAuth,
    signOut,
    User as FirebaseUser,
    sendPasswordResetEmail,
    confirmPasswordReset
} from "firebase/auth";
import {
    collection,
    doc, getDocs,
    getFirestore,
    onSnapshot,
    query,
    updateDoc,
    where,
    documentId,
    setDoc,
    deleteDoc,
    DocumentSnapshot,
    deleteField,
    getDoc,
    orderBy
} from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import { FirebaseConfig } from "lib/coplay/constants/generated";
import { XboxUser, OauthActions, BasicResponse, Friends, XBLCollatedConversations, XboxConsole, XMProfile, UserPrivacySettings, ScheduleEvent, LogEntry, MonthlyReport, XMAPIKeyCrumb, BasicReport } from "../types/BackendTypes";

const App = initializeApp(FirebaseConfig);
const analytics = getAnalytics(App);
const DB = getFirestore(App);
let DBUser: FirebaseUser | null = null;
let XboxUserObserver: (() => void)[] = [];

const addXboxUsersObserver = (observer: () => void) => {
    XboxUserObserver.push(observer);
}

const Login = async (email: string, password: string): Promise<FirebaseUser | null> => {
    const userCredential = await signInWithEmailAndPassword(getAuth(), email, password);
    DBUser = userCredential.user;
    return DBUser;
}

const Logout = async (): Promise<void> => {
    await signOut(getAuth());
    DBUser = null;
}

const passwordReset = async (email: string) => {
    return await sendPasswordResetEmail(getAuth(), email)
}

const confirmThePasswordReset = async (oobCode: string, newPassword: string) => {
    return await confirmPasswordReset(getAuth(), oobCode, newPassword)
}

const getDbToken = async (): Promise<string> => {
    if (DBUser === null) {
        throw new Error("User is not logged in");
    }
    return await DBUser.getIdToken(true);
}

const handleAuthChange = async (user: FirebaseUser | null) => {
    DBUser = user;
    XboxUserObserver = []; // Clear observers whenever auth state changes
}

getAuth().onAuthStateChanged(handleAuthChange);

const getXboxUsers = async (callback: (fetchedUsers: XboxUsersSet) => void,) => {
    try {
        if (!DBUser) return callback(new XboxUsersSet());
        const xboxUsers: XboxUsersSet = new XboxUsersSet()
        const xboxUsersRef = collection(DB, `${DBUser.email}/data/users`);
        const xboxUsersSnapshot = await getDocs(xboxUsersRef);
        xboxUsersSnapshot.forEach((doc) => {
            // SUPER HACK
            const user = doc.data()
            // updatedAt is actually a firebase timestamp
            // convert it to a Date object
            user.updatedAt = user.updatedAt.toDate();
            xboxUsers.add(user as XboxUser);
        });
        callback(xboxUsers);
    } catch (e) {
        console.log(e);
        callback(null);
    }
};

const subscribeToXboxUsers = (callback: (fetchedUsers: XboxUsersSet) => void) => {
    const xboxUsersRef = collection(DB, `${DBUser.email}/data/users`);
    const unsubscribe = onSnapshot(xboxUsersRef, (snapshot) => {
        const xboxUsers: XboxUsersSet = new XboxUsersSet()
        snapshot.forEach((doc) => {
            // SUPER HACK
            const user = doc.data()
            // updatedAt is actually a firebase timestamp
            // convert it to a Date object
            user.updatedAt = user.updatedAt.toDate();
            xboxUsers.add(user as XboxUser);
        });
        for (const observer of XboxUserObserver) {
            observer();
        }
        XboxUserObserver = [];
        callback(xboxUsers);
    });
    return unsubscribe;
};

const subscribeLimit = (setLimit: Function) => {
    try {
        return onSnapshot(
            doc(DB, `${DBUser.email}/config`),
            (doc: DocumentSnapshot) => setLimit(doc.data()?.num_users)
        )
    } catch (error) {
        console.log(`[subscribeLimit] Error: ${error}`)
    }
}

const getFirebaseToken = (callback: (firebaseToken: string) => void) => {
    DBUser.getIdToken(true).then((token: string) => {
        callback(token);
    });
};

const getErrorDetails = async (action: OauthActions, requestId: number, emailAddress: string) => {
    try {
        let path = `${DBUser.email}/data/errors/${action}/${requestId}/${emailAddress}`;
        const docRef = doc(DB, path);
        const docSnapshot = await getDoc(docRef);
        if (docSnapshot.exists()) {
            return docSnapshot.data() as BasicResponse;
        } else {
            return null;
        }
    } catch (e) {
        console.log(`[getErrorDetails] Error: ${e}`);
    }
};

const getXboxUserFriends = async (emailAddress: string): Promise<Friends> => {
    try {
        let path = `${DBUser.email}/data/friends/`;
        const q = query(collection(DB, path), where(documentId(), "==", emailAddress))
        const querySnapshot = await getDocs(q);
        if (querySnapshot.empty) {
            throw new Error(`Could not find for ${emailAddress}`);
        }
        const friends = querySnapshot.docs[0];
        return friends.data() as Friends;
    } catch (e) {
        console.log(`[getErrorDetails] getXboxUserFriends Error: ${e}`);
    }
};

const getXboxUserConversations = async (emailAddress: string): Promise<XBLCollatedConversations> => {
    try {
        let path = `${DBUser.email}/data/conversations/`;
        const q = query(collection(DB, path), where(documentId(), "==", emailAddress))
        const querySnapshot = await getDocs(q);
        if (querySnapshot.empty) {
            throw new Error(`Could not find for ${emailAddress}`);
        }
        const conversations = querySnapshot.docs[0];
        return conversations.data() as XBLCollatedConversations;
    } catch (e) {
        console.log(`[getErrorDetails] Error: ${e}`);
    }
};

const getContactDetails = async (callback: (contactDetails: ContactDetails) => void) => {
    try {
        let path = `${DBUser.email}/data/contact`;
        const contactRef = collection(DB, path);
        const q = query(contactRef);
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            const data = doc.data() as ContactDetails;
            callback(data);
            return;
        });
    } catch (e) {
        console.log(`[getContactDetails] Error: ${e}`);
    }
};

const updateContactDetails = (enteredDetails: { [key: string]: string }) => {
    setDoc(doc(DB, `${DBUser.email}/data/contact/me`), enteredDetails, { merge: true })
        .catch((error) => console.log(`[updateContactDetails] Error: ${error}`))
}

const updateNotes = async (xboxUser: XboxUser, notes: string) => {
    try {
        let path = `${DBUser.email}/data/users/${xboxUser.emailAddress}`;
        const userRef = doc(DB, path);
        await updateDoc(userRef, {
            notes: notes
        });
    } catch (e) {
        console.log(`[getContactDetails] Error: ${e}`);
    }
};

const getConsoleDetails = async (emailAddress: string): Promise<XboxConsole> => {
    try {
        let path = `${DBUser.email}/data/consoles/`;
        const q = query(collection(DB, path), where(documentId(), "==", emailAddress))
        const querySnapshot = await getDocs(q);
        if (querySnapshot.empty) {
            throw new Error(`Could not find for ${emailAddress}`);
        }
        const consoles = querySnapshot.docs[0];
        return consoles.data() as XboxConsole;
    } catch (e) {
        console.log(`[getConsoleDetails] Error: ${e}`);
    }
}

const subscribeToConsoleDetails = (emailAddress: string, callback: (consoleDetails: XboxConsole) => void) => {
    try {
        let path = `${DBUser.email}/data/consoles/`;
        const q = query(collection(DB, path), where(documentId(), "==", emailAddress))
        const unsubscribe = onSnapshot(q, (snapshot) => {
            snapshot.forEach((doc) => {
                callback(doc.data() as XboxConsole);
            })
        });
        return unsubscribe;
    } catch (e) {
        console.log(`[subscribeToConsoleDetails] Error: ${e}`);
    }
}

export const getMonthlyUsageReports = async (year: string, month: string) => {
    try {
        let path = `${DBUser.email}/data/usage/${year}/monthly/${month}/`;
        const monthDoc = doc(DB, path);
        const monthSnapshot = await getDoc(monthDoc);
        if (!monthSnapshot.exists()) {
            return null
        }
        const monthData = monthSnapshot.data() as MonthlyReport;
        console.log('monthData: ', monthData);
        return monthData;
    } catch (e) {
        console.log(`[getMonthlyUsageReports] Error: ${e}`);
        throw (e);
    }
}

export const getXMLogs = async () => {
    try {
        let path = `${DBUser.email}/data/xmlogs`;
        // order by timestamp descending
        const q = query(collection(DB, path), orderBy("timestamp", "desc"));
        const querySnapshot = await getDocs(q);
        const logs: (BasicReport | BasicResponse)[] = [];
        querySnapshot.forEach((doc) => {
            const data = doc.data() as BasicReport | BasicResponse;
            logs.push(data);
        });
        return logs;
    } catch (e) {
        console.log(`[getMonthlyUsageReports] Error: ${e}`);
        throw (e);
    }
}

const renameProfile = async (profile: XMProfile) => {
    try {
        await Promise.all((await getDocs(query(
            collection(DB, `${DBUser.email}/data/users`),
            where("xmProfile.id", "==", profile.id)
        ))).docs.map((doc: DocumentSnapshot) => {
            return updateDoc(doc.ref, "xmProfile.name", profile.name)
        }))
    } catch (error) {
        console.log(`[renameProfile] Error: ${error}`)
    }
}

const createProfile = async (profile: XMProfile) => {
    try {
        let path = `${DBUser.email}/data/profiles/`;
        const profileRef = doc(DB, path, `${profile.id}`);
        await setDoc(profileRef, profile);
    } catch (e) {
        console.log(`[createProfile] Error: ${e}`);
    }
}

const deleteProfile = async (profile: XMProfile) => {
    try {
        let path = `${DBUser.email}/data/profiles/`;
        const profileRef = doc(DB, path, `${profile.id}`);
        await deleteDoc(profileRef);
        await Promise.all((await getDocs(query(
            collection(DB, `${DBUser.email}/data/users`),
            where("xmProfile.id", "==", profile.id)
        ))).docs.map((doc: DocumentSnapshot) => {
            return setDoc(doc.ref, { xmProfile: deleteField() }, { merge: true })
        }))
    } catch (e) {
        console.log(`[deleteProfile] Error: ${e}`);
    }
}

const getProfiles = async (): Promise<XMProfile[]> => {
    try {
        let path = `${DBUser.email}/data/profiles/`;
        const q = query(collection(DB, path),)
        const querySnapshot = await getDocs(q);
        const allProfiles: XMProfile[] = [];
        if (querySnapshot.empty) {
            throw new Error(`Could not get profiles`);
        }
        querySnapshot.forEach((doc) => {
            const data = doc.data() as XMProfile;
            allProfiles.push(data);
        });
        return allProfiles;
    } catch (e) {
        console.log(`[getProfiles] Error: ${e}`);
    }
}


const subscribeToProfiles = (callback: (profiles: XMProfile[]) => void) => {
    try {
        let path = `${DBUser.email}/data/profiles/`;
        const q = query(collection(DB, path),)
        const unsubscribe = onSnapshot(q, (snapshot) => {
            const allProfiles: XMProfile[] = [];
            snapshot.forEach((doc) => {
                const data = doc.data() as XMProfile;
                allProfiles.push(data);
            });
            callback(allProfiles);
        });
        return unsubscribe;
    } catch (e) {
        console.log(`[subscribeToProfiles] Error: ${e}`);
    }
}

const getPrivacySettings = async (emailAddress: string) => {
    try {
        let path = `${DBUser.email}/data/privacy`;

        const q = query(collection(DB, path), where("emailAddress", "==", emailAddress));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
            return querySnapshot.docs[0].data() as UserPrivacySettings;
        } else {
            return null;
        }

    } catch (e) {
        console.log(`[getPrivacySettings] Error: ${e}`);
        return null;
    }
}

const getXMProfiles = async () => {
    try {
        let path = `${DBUser.email}/data/profiles`;

        const q = query(collection(DB, path));
        const querySnapshot = await getDocs(q);
        let profiles: XMProfile[] = [];
        querySnapshot.forEach((doc) => {
            const data = doc.data() as XMProfile;
            profiles.push(data);
        });
        return profiles;
    } catch (e) {
        console.log(`[getXMProfiles] Error: ${e}`);
        return null;
    }
}

const getAllSchedules = async () => {
    try {
        let path = `${DBUser.email}/data/schedules`;

        const q = query(collection(DB, path));
        const querySnapshot = await getDocs(q);
        let schedules: ScheduleEvent[] = [];
        querySnapshot.forEach((doc) => {
            const data = doc.data();
            schedules.push(data as ScheduleEvent);
        });
        return schedules;
    } catch (e) {
        console.log(`[getAllSchedules] Error: ${e}`);
        return null;
    }
}

const getUserLog = async (emailAddress: string): Promise<LogEntry[]> => {
    try {
        const userLogs = (
            await getDoc(doc(DB, `${DBUser.email}/data/userLogs/${emailAddress}`))
        ).data()

        if (userLogs) {
            return userLogs.entries
        }

        return []
    } catch (e) {
        console.log(`[getUserLog] Error: ${e}`);
        return [];
    }
}

const getScheduleWithId = async (id: string): Promise<ScheduleEvent> => {
    try {
        let path = `${DBUser.email}/data/schedules`;

        const q = query(collection(DB, path), where(documentId(), "==", id));
        const querySnapshot = await getDocs(q);
        let schedules: ScheduleEvent[] = [];
        if (querySnapshot.empty)
            throw new Error("Could not find schedule");

        return querySnapshot.docs[0].data() as ScheduleEvent;
    } catch (e) {
        console.log(`[getScheduleWithId] Error: ${e}`);
        return null;
    }
}

const getSchedulesWithId = async (ids: string[]): Promise<ScheduleEvent[]> => {
    try {
        let path = `${DBUser.email}/data/schedules`;

        const q = query(collection(DB, path), where(documentId(), "in", ids));
        const querySnapshot = await getDocs(q);
        let schedules: ScheduleEvent[] = [];
        if (querySnapshot.empty)
            throw new Error("Could not find schedule");

        querySnapshot.forEach((doc) => {
            const data = doc.data();
            schedules.push(data as ScheduleEvent);
        });
        return schedules;
    } catch (e) {
        console.log(`[getScheduleWithId] Error: ${e}`);
        return null;
    }
}

const subscribeToXboxUser = (emailAddress: string, callback: (xboxUser: XboxUser) => void) => {
    try {
        let path = `${DBUser.email}/data/users/`;
        const q = query(collection(DB, path), where(documentId(), "==", emailAddress));
        const unsubscribe = onSnapshot(q, (snapshot) => {
            // SUPER HACK
            const user = snapshot.docs[0].data()
            user.updatedAt = user.updatedAt.toDate();
            callback(user as XboxUser);
        });
        return unsubscribe;
    } catch (e) {
        console.log(`[subscribeToXboxUser] Error: ${e}`);
    }
}

const subscribeToFriends = (emailAddress: string, callback: (friends: Friends) => void) => {
    try {
        let path = `${DBUser.email}/data/friends/`;
        const q = query(collection(DB, path), where(documentId(), "==", emailAddress));
        const unsubscribe = onSnapshot(q, (snapshot) => {
            const data = snapshot.docs[0].data() as Friends;
            callback(data);

            // if (snapshot.empty)
            //     return callback(null);
            // else
            //     callback(snapshot.docs[0].data() as Friends);
        });
        return unsubscribe;
    } catch (e) {
        console.log(`[subscribeToFriends] Error: ${e}`);
    }
}

const subscribeToSettings = (emailAddress: string, callback: (settings: UserPrivacySettings) => void) => {
    try {
        let path = `${DBUser.email}/data/privacy/`;
        const q = query(collection(DB, path), where(documentId(), "==", emailAddress));
        const unsubscribe = onSnapshot(q, (snapshot) => {
            if (snapshot.empty) {
                console.log("No settings found")
                return callback(null);
            } else
                callback(snapshot.docs[0].data() as UserPrivacySettings);
        });
        return unsubscribe;
    } catch (e) {
        console.log(`[subscribeToSettings] Error: ${e}`);
    }
}

const subscribePrivileges = (emailAddress: string, setPrivileges: Function) => {
    try {
        return onSnapshot(
            doc(DB, `${DBUser.email}/data/privleges/${emailAddress}`),
            (snapshot) => setPrivileges(snapshot.data().privleges)
        )
    } catch (error) {
        console.log(`[subscribePrivileges] Error: ${error}`)
    }
}

const subscribeToConversations = (emailAddress: string, callback: (conversations: XBLCollatedConversations) => void) => {
    try {
        let path = `${DBUser.email}/data/conversations/`;
        const q = query(collection(DB, path), where(documentId(), "==", emailAddress));
        const unsubscribe = onSnapshot(q, (snapshot) => {
            if (snapshot.empty)
                return callback(null);
            else
                callback(snapshot.docs[0].data() as XBLCollatedConversations);
        });
        return unsubscribe;
    } catch (e) {
        console.log(`[subscribeToConversations] Error: ${e}`);
    }
}

const subscribeToGlobalSchedules = (callback: (events: ScheduleEvent[]) => void) => {
    try {
        let path = `${DBUser.email}/data/schedules/`;
        const q = query(collection(DB, path));
        const unsubscribe = onSnapshot(q, (snapshot) => {
            if (snapshot.empty)
                callback([]);
            else {
                const events: ScheduleEvent[] = [];
                snapshot.forEach((doc) => {
                    events.push((doc.data() as ScheduleEvent));
                });
                callback(events);
            }
        });
        return unsubscribe;
    } catch (e) {
        console.log(`[subscribeToConversations] Error: ${e}`);
    }
}

const subscribeToApiKeys = (callback: (fetchedKeys: XMAPIKeyCrumb[]) => void) => {
    const apiKeysRef = collection(DB, `${DBUser.email}/data/keys`);
    const unsubscribe = onSnapshot(apiKeysRef, (snapshot) => {
        const apiKeys: XMAPIKeyCrumb[] = [];
        snapshot.forEach((doc) => {
            const key = doc.data() as XMAPIKeyCrumb;
            apiKeys.push(key);
        });
        callback(apiKeys);
    });
    return unsubscribe;
};

const updateScheduleName = async (scheduleId: string, newName: string): Promise<void> => {
    try {
        const path = `${DBUser.email}/data/schedules/${scheduleId}`;
        const scheduleRef = doc(DB, path);

        await updateDoc(scheduleRef, {
            name: newName
        });
    } catch (e) {
        console.error(`[updateScheduleName] Error: ${e}`);
        throw e;
    }
}

export const duplicateProfile = async (profile: XMProfile): Promise<void> => {
    try {
        const newId = Date.now().toString(36) + Math.random().toString(36).substr(2);
        const newName = `${profile.name} Copy`;
        const path = `${DBUser.email}/data/profiles/${newId}`;
        const profileRef = doc(DB, path);
        const newProfile = { ...profile, id: newId, name: newName };
        await setDoc(profileRef, newProfile);
    } catch (e) {
        console.error(`[duplicateProfile] Error: ${e}`);
        throw e;
    }
};

export {
    addXboxUsersObserver,
    Login,
    Logout,
    passwordReset,
    confirmThePasswordReset,
    getDbToken,
    handleAuthChange,
    getXboxUsers,
    subscribeToXboxUsers,
    subscribeLimit,
    getFirebaseToken,
    getErrorDetails,
    getXboxUserFriends,
    getXboxUserConversations,
    getContactDetails,
    updateContactDetails,
    updateNotes,
    getConsoleDetails,
    subscribeToConsoleDetails,
    renameProfile,
    createProfile,
    deleteProfile,
    getProfiles,
    subscribeToProfiles,
    getPrivacySettings,
    getXMProfiles,
    getAllSchedules,
    getUserLog,
    getScheduleWithId,
    getSchedulesWithId,
    subscribeToXboxUser,
    subscribeToFriends,
    subscribeToSettings,
    subscribePrivileges,
    subscribeToConversations,
    subscribeToGlobalSchedules,
    subscribeToApiKeys,
    updateScheduleName
}