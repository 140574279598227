import { Spinner, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Button, Flex, Progress, Text } from "@chakra-ui/react";
import { subscribeToConsoleDetails } from "lib/coplay/backend/FirebaseFunctions";
import { XboxConsole } from "lib/coplay/types/BackendTypes";
import { useState, useEffect } from "react";
import { linkConsole } from "../../../../backend/ApiRequests";
import { ConsoleDetailsHeader } from "./ConsoleDetailsHeader";
import { ConsoleDetailsList } from "./ConsoleDetailsList";


enum ConsoleDetailsState {
    LOADING = "LOADING",
    ERROR = "ERROR",
    SUCCESS = "SUCCESS"
}

export const ConsoleDetailsModal = (props: { emailAddress: string, isOpen: boolean, onClose: () => void }) => {
    const [progress, setProgress] = useState<ConsoleDetailsState>(ConsoleDetailsState.LOADING);
    const [consoleDetails, setConsoleDetails] = useState<XboxConsole>();

    useEffect(() => {
        const unsub = subscribeToConsoleDetails(props.emailAddress, (console: XboxConsole) => {
            setConsoleDetails(console);
            setProgress(ConsoleDetailsState.SUCCESS);
        })
        return unsub

    }, [])


    const onClose = () => {
        props.onClose();
    }


    const unlinkConsole = () => {
        console.log("Unlinking Console");
        if (consoleDetails === undefined) {
            console.log("No Console to unlink")
            return;
        }

        linkConsole(props.emailAddress, consoleDetails.id, false).then((result) => {
            console.log(result);
            onClose();
        }).catch((error) => {
            console.log(error);
            setProgress(ConsoleDetailsState.ERROR);
        })
        setProgress(ConsoleDetailsState.LOADING);
    }




    const states = {
        LOADING: <LoadingScreen />,
        ERROR: <>Error</>,
        SUCCESS:
            (<>
                <ConsoleDetailsHeader console={consoleDetails} onUnlink={unlinkConsole} emailAddress={props.emailAddress} />
                <ConsoleDetailsList emailAddress={props.emailAddress} console={consoleDetails} />
            </>),
    }


    return (
        <Modal isOpen={props.isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Console Details</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {states[progress]}
                </ModalBody>

                <ModalFooter>
                    <Button onClick={props.onClose} variant='ghost'>Close</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}

const LoadingScreen = () => {
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setProgress((prev) => {
                if (prev >= 100) {
                    clearInterval(interval);
                    return 100; // Ensure it doesn't exceed 100
                }
                return prev + (100 / 60); // Increment progress over 60 seconds
            });
        }, 1000); // Update every second

        return () => clearInterval(interval); // Cleanup on unmount
    }, []);

    return (
        <Flex direction='column' alignItems='center' justifyContent='center' py='50px'>
            <Spinner
                thickness='4px'
                speed='0.65s'
                emptyColor='gray.200'
                color='blue.500'
                size='xl'
            />
            <Progress value={progress} size='sm' width='100%' mt={4} /> {/* Added loading bar */}
            <Text mt={2}>{progress.toFixed(0)}% Loading...</Text> {/* Display progress percentage */}
        </Flex>
    )
}