import { Box, Icon, SimpleGrid, Spinner, Flex } from "@chakra-ui/react";
import React from "react";
import { MdOutlineErrorOutline } from "react-icons/md";
import NumUsers from "lib/coplay/components/views/NumUsers";
import ActivityGraph from "lib/coplay/components/views/usage/ActivityGraph";
import { useXboxUsers } from "../../DataProvider";
import Card from "lib/horizon/components/card/Card";
import { getMonthlyUsageReports, getXMLogs } from "../../../backend/FirebaseFunctions";
import { BasicReport, BasicRequest, BasicResponse, MonthlyReport } from "lib/coplay/types/BackendTypes";
import MonthMenu from "./MonthMenu";
import { MonthOptions } from "./MonthMenu";
import ErrorsAndWarningsStats from "./ErrorsAndWarningsStats";
import { RequestLogRenderer } from "./RequestLogRenderer";

export default function UsageView() {
	const [monthlyReport, setMonthlyReport] = React.useState<MonthlyReport | null>(null);
	const [logs, setLogs] = React.useState<(BasicReport | BasicResponse)[]>([]);
	const [selectedMonth, setSelectedMonth] = React.useState<string>(new Date().toLocaleString('default', { month: 'long' }));
	const [year, setYear] = React.useState<string>(new Date().getFullYear().toString());
	const [isLoading, setIsLoading] = React.useState<boolean>(true);

	React.useEffect(() => {
		setIsLoading(true);
		getMonthlyUsageReports(year, MonthOptions[selectedMonth]).then((report: MonthlyReport) => {
			setMonthlyReport(report);
			setIsLoading(false);
		}).catch((error) => {
			console.error('[Usage] Error getting reports: ', error);
			setIsLoading(false);
		})
	}, [selectedMonth]);

	React.useEffect(() => {
		getXMLogs().then((logs: (BasicReport | BasicResponse)[]) => {
			setLogs(logs);
		}).catch((error) => {
			console.error('[Usage] Error getting logs: ', error);
		})
	}, [])

	return (
		<Box pt={{ base: '80px', xl: '80px' }} display="flex" flexDirection="column" minH="100vh">


			<SimpleGrid columns={{ base: 1, md: 2, lg: 3, '2xl': 6 }} gap='20px' mb='20px'>
				<Card py='5px' alignContent='center' justifyContent='center'>
					<MonthMenu selectedMonth={selectedMonth} setMonth={setSelectedMonth} />
				</Card>
				<NumUsers />
				<ErrorsAndWarningsStats />
			</SimpleGrid>

			<Box mb="30px" flex="1" display="flex" flexDirection="column">
				{isLoading && <UsageLoadingSpinner />}
				{!isLoading && monthlyReport && logs && (
					<>
						<ActivityGraph monthlyReport={monthlyReport} month={selectedMonth} />
						<Box mt="20px">
							<RequestLogRenderer requestLog={logs} />
						</Box>
					</>
				)}
				{!isLoading && !monthlyReport && <ZeroState />}
			</Box>
		</Box >
	);
}

function UsageLoadingSpinner() {
	return (
		<Card alignItems='center' flexDirection='column' w='100%' justifyContent='center'>
			<Flex align='center' justify='center' w='100%' px='15px' py='10px'>
				<Spinner size="xl" />
			</Flex>
		</Card>
	);
}

function ZeroState() {
	return (
		<Card alignItems='center' flexDirection='column' w='100%' justifyContent='center'>
			<Flex align='center' justify='center' w='100%' px='15px' py='10px'>
				<Icon w='32px' h='32px' as={MdOutlineErrorOutline} color='gray.500' />
				<Box ml='10px' color='gray.500'>
					No data available
				</Box>
			</Flex>
		</Card>
	);
}